/**
 * @module PageFooter
 * @changed 2024.06.16, 22:58
 */

@import '../shared';

.dimmed {
  opacity: 0.5;
}

.primaryColor {
  color: $primaryColor;
}
.primaryBgColor {
  background-color: $primaryColor;
}

.errorIcon {
  font-size: 3rem;
  opacity: 0.1;
}

a.mail,
a.external {
  &:after {
    transition: opacity $transitionTimeMs;
    opacity: 0.4;
    margin-left: 0.3em;
    display: inline-block;
    line-height: 1;
  }
  &:not(:hover):after {
    @include LinkAsText;
  }
  &:hover:after {
    opacity: 1;
  }
}
a.external {
  &:after {
    content: '\f143'; // bootstrap icon arrow-up-right-square: https://icons.getbootstrap.com/icons/arrow-up-right-square/
    font-family: bootstrap-icons, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    vertical-align: -0.15em;
  }
}
/* a.mail {
 *   &:after {
 *     // content: '@';
 *     // vertical-align: 0.125em;
 *     content: '\f32f';
 *   }
 * }
 */
