/**
 * @module PageHeaderLogo
 * @changed 2024.06.17, 22:22
 */

@use 'sass:color';

@import '../shared';

.PageHeaderLogo {
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  // border: 1px solid blue; // DEBUG
  height: 4.5rem;
  [data-page='main'] & {
    display: none;
  }
  .wideView & {
    width: 20vw;
    max-width: 20em;
  }

  .Logo {
    display: inline-block;
    width: 100%;
    background-image: url('/static/images/logo/full/ll-logo-wx.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    .wideView & {
      background-position: left center;
    }
    & {
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
  [data-page='main'] & {
    width: 1.75em;
    opacity: 0;
    pointer-events: none;
    * {
      display: none;
    }
  }
}
