/**
 * @module PageFooter
 * @changed 2024.06.21, 03:49
 */

@import '../shared';

.PageFooter {
  width: 100%;
  max-width: $mainColumnWidth;
  padding: 1em;
  margin: 0 auto;

  @media (min-width: calc($mainColumnWidth + 2em)) {
    padding: 1em 0;
  }
  @media (min-width: calc($mainColumnWidth + 2em)) {
    padding: 1em 0;
  }

  font-size: 95%;

  a:not(:hover) {
    text-decoration: none;
  }

  .FooterContainer {
    [data-page='regular'] & {
      border-top: 1px solid $primaryColor;
      padding-top: 1em;
    }
    display: flex;
    gap: 0.2em 2em;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    [data-page='main'] & {
      justify-content: center;
    }
    text-align: center;
    @media (min-width: $mainColumnWidth) {
      flex-direction: row;
      align-items: flex-start;
      text-align: left;
    }
  }

  .FooterCopyright {
    .delimiter {
      opacity: 0.3;
      padding: 0 0.25em;
      display: inline-block;
    }
  }

  .SocialList {
    margin: 0;
    padding: 0;
    list-style: none;
    min-width: max-content;
    display: flex;
    gap: 1em;
  }
  .SocialListLink {
    align-items: center;
    gap: 0.5em;
    display: inline-flex;
    i {
      opacity: 0.5;
    }
  }
}
