/**
 * @module bootstrap-theme-fixes
 * @changed 2024.06.12, 05:27
 */

@use 'sass:color';

@import '../shared';

:root {
  --bs-primary-rgb: #{$primaryColorRgb};
  --bs-link-color-rgb: var(--bs-primary-rgb);
  --bs-link-color: #{$primaryColor}; // #0d6efd;
  // --bs-link-color-rgb: 13, 110, 253;
  // --bs-link-decoration: underline;
  --bs-link-hover-color: #{$primaryDarkColor}; // #0a58ca;
  --bs-link-hover-color-rgb: var(--bs-link-hover-color);
  // --bs-link-hover-color-rgb: 10, 88, 202;
  // --bs-border-color: #{$primaryColor};
}
