/**
 * @module PageHeaderNavigation
 * @changed 2024.06.18, 01:08
 */

@use 'sass:color';

@import '../shared';

.PageHeaderNavigation {
  display: flex;
  justify-content: center;
  gap: 1em;
  // Mobile view
  .mobileView &.show {
    flex-direction: column;
    padding: 0.5em 0;
  }
  // Main page
  [data-page='main'] body:not(.mobileView) & {
    flex-direction: column-reverse;
  }
}
