/**
 * @module headings
 * @changed 2024.06.18, 11:08
 */

@use 'sass:color';

@import '../shared';

h1,
h2,
h3 {
  color: $primaryColor;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  a.header-anchor {
    @include LinkAsTextWithHover;
    text-decoration: none;
    opacity: 0;
    font-size: 80%;
  }
  &:hover {
    .header-anchor {
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
}
h3,
h4 {
  @include ThemedText;
  opacity: 0.75;
  // font-size: 100%;
}
