/**
 * @module Page
 * @changed 2024.06.21, 03:48
 */

@import '../shared';

.Page {
  position: absolute;

  border: 0;
  padding: 0;
  margin: 0;

  font-family: $defaultFontFamily;
  // font-size: $fontSize;
  // line-height: $lineHeight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overscroll-behavior: contain;

  // Flex layout
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media (max-width: $maxColumnWidth) {
    grid-gap: 0;
  }

  @media (max-width: $mainColumnMaxWidth) {
    grid-template-columns: $mobileBorderWidth 1fr $mobileBorderWidth;
  }
}

// page__content
.PageContentLayout {
  position: relative;

  // Flex layout
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  // Adjustable top margin (adaptive, different for main and regular page, and for opened menu)
  margin-top: $headerHeight;
  .showNavigation &,
  [data-page='main'] .wideView & {
    margin-top: $headerHeightMain;
  }

  // Grid layout
  grid-column: 1 / span 3;
  @media (max-width: $maxColumnWidth) {
    grid-column: 2 / span 1;
  }
}

.VisualAnimation {
  z-index: $overContentZIndex;
  @include FullSizeContainer;
  [data-page='main'] & {
    position: absolute;
  }
}

.PageVisual {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1020;
  overflow: hidden;
  bottom: auto;
  // Adjust main height
  height: 250px;
  .showNavigation &,
  [data-page='main'] .wideView & {
    height: 90%;
  }
  background: linear-gradient(180deg, $primaryColor 0%, rgba(255, 255, 255, 0) 70%);
  pointer-events: none;
}
