/**
 * @module bodyThemes
 * @changed 2024.06.21, 03:59
 */

@use 'sass:color';

@import '../shared';

// Themes...

body,
[data-theme='light'],
[data-theme='light'] body {
  background-color: $backgroundColor;
}

.onLight,
body,
[data-theme='light'],
[data-theme='light'] body {
  color: $defaultTextColor;
  a {
    transition: all $transitionTimeMs;
    &:hover {
      color: $primaryLightenColor;
    }
  }
}

[data-theme='dark'],
[data-theme='dark'] body {
  background-color: $backgroundDarkColor;
}

.onRed,
.onDark,
[data-theme='dark'],
[data-theme='dark'] body {
  color: $defaultTextOnDarkColor;
  a {
    $color: #fff;
    color: $color;
    opacity: 0.8;
    &:hover {
      color: $primaryColor;
      opacity: 1;
    }
  }
}

body .onRed,
.onRed {
  $color: #fff;
  color: color.change($color, $alpha: 0.8);
  a {
    opacity: 0.9;
    &:hover {
      color: $color;
    }
  }
}

.linkAsText {
  @include LinkAsText;
}
