/**
 * @module PageMenu
 * @changed 2024.06.14, 02:15
 */

@import '../shared';

.PageMenu {
  flex: 1;
  display: flex;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 0.2em 1em;
  li {
    display: inline-block;
  }
  a {
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
}
