@mixin PageWrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@mixin FullSizeContainer {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@mixin ThemedText {
  &,
  [data-theme='light'] body &,
  [data-theme='light'] & {
    color: $defaultTextColor;
  }
  [data-theme='dark'] body &,
  [data-theme='dark'] & {
    color: $defaultTextOnDarkColor;
  }
}
@mixin LinkAsText {
  &,
  // [data-theme='light'] body &,
  [data-theme='light'] & {
    color: $defaultTextColor;
  }
  // [data-theme='dark'] body &,
  [data-theme='dark'] & {
    color: $defaultTextOnDarkColor;
  }
}
@mixin LinkAsTextWithHover {
  &,
  &:hover {
    @include LinkAsText;
  }
}
