@use 'sass:map';
@use 'sass:math';
@use 'sass:color';

@import '../../../static/vendor/bootstrap-5.3.2/scss/functions';
@import '../../../static/vendor/bootstrap-5.3.2/scss/variables';

// Font...

$defaultFontSize: 14px;

$lineHeight: 1.42;
$fontSize: 20px;
$lineSpace: calc($lineHeight * 1em);

$defaultFontFamily: 'Roboto', 'Helvetica', 'Arial', sans-serif;

// Breakpoints:...

$screenXsMin: map.get($grid-breakpoints, 'xs'); // 0
$screenSmMin: map.get($grid-breakpoints, 'sm'); // 576px
$screenMdMin: map.get($grid-breakpoints, 'md'); // 768px
$screenLgMin: map.get($grid-breakpoints, 'lg'); // 992px
$screenXlMin: map.get($grid-breakpoints, 'xl'); // 1200px
$screenXxlMin: map.get($grid-breakpoints, 'xxl'); // 1400px

$screenXsMax: map.get($grid-breakpoints, 'sm'); // 576px
$screenSmMax: map.get($grid-breakpoints, 'md'); // 768px
$screenMdMax: map.get($grid-breakpoints, 'lg'); // 992px
$screenLgMax: map.get($grid-breakpoints, 'xl'); // 1200px
$screenXlMax: map.get($grid-breakpoints, 'xxl'); // 1400px

// $mainColumnWidth: $screenMdMin; // 700px;
// $maxColumnWidth: $screenLgMin; // 1000px;
$mainColumnWidth: 700px;
$mainColumnMaxWidth: calc($mainColumnWidth + 50px);
$maxColumnWidth: 1000px;
$maxColumnWidthWithGap: calc($maxColumnWidth + 250px);
$mobileBorderWidth: 15px;

$maxSideColumnWidth: 240px;

$articleThumbSize: 120px;

// Collapsable widths...

$collapseWidth: $screenMdMin; // $grid-float-breakpoint; // =@screen-sm-min=768px ? =@screen-md-min=992px
$navbarCollapse: $screenLgMin;
$navbarCollapseMax: $screenMdMax;
$midbarCollapse: $collapseWidth;

// Primary color...

$primaryColor: #c20;
// $primaryLightColor: #f20;
$primaryLightColor: color.adjust($primaryColor, $lightness: 10%);
$primaryLightenColor: color.adjust($primaryLightColor, $lightness: 10%);
$primaryDarkColor: color.adjust($primaryColor, $lightness: -10%);
$primaryDarkenColor: color.adjust($primaryDarkColor, $lightness: -10%);
$primaryColorRgb: #{toRGBValues($primaryColor)};

$textColor: #333;

// // Example: DdS palette...
// $primaryLightColor: #8a9;
// $primaryColor: #486;
// $primaryDarkColor: #375;
// $primaryDarkenColor: color.adjust($primaryDarkColor, $lightness: -10%);
// $primaryDarkestColor: #3c5343;
// $primaryColorRgb: 68, 136, 102;

// Misc colors...

// UNUSED?
$xdarkBlueColor: #036;
$darkBlueColor: #047;
$mediumBlueColor: #157;
$moodBlueColor: #6ac;
$extralightBlueColor: #def;

$defaultTextColor: #333;
$defaultTextOnDarkColor: #ccc;

$backgroundColor: #fff;
$backgroundDarkColor: #333;

// Navbar...

$navbarMainHeight: 60px;
$navbarPlusHeight: 50px;

// Timeouts...
// RegEx: (moment|transition|animation|effect)Time\b

$momentTimeMs: 150ms;
$transitionTimeMs: 250ms;
$animationTimeMs: 500ms;
$effectTimeMs: 1000ms;

// Adaptive header & visual...

$headerHeight: 130px;
$headerHeightMain: 45vh;
$overContentZIndex: 100;
