.MainPageContent {
  &.Article {
    position: relative;
    padding: 0 1em;
    top: -2em;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1em;
    max-width: $mainColumnWidth;
    .MainPageLogo {
      margin: 0 auto;
      transition: all $transitionTimeMs;
      opacity: 0.7;
      width: 100%;
      max-width: 40em;
      /* // Old approach
      content: url('/static/images/logo/full/ll-logo.svg');
      [data-theme='dark'] & {
        content: url('/static/images/logo/full/ll-logo-w.svg');
      }
      */
      .key {
        fill: black;
        [data-theme='dark'] & {
          fill: white;
        }
      }
      .red {
        fill: $primaryColor;
      }
    }
    .MainPageDescription {
      position: relative;
    }
  }
}
