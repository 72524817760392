/**
 * @module PageHeader
 * @changed 2024.06.21, 03:49
 */

@import '../shared';

.PageHeader {
  width: 100%;
  display: flex;
  gap: 1em;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1em;

  // Adjust main height
  padding-top: 1em;
  padding-bottom: 2.5em;

  // Adaptive sizes...
  height: $headerHeight;

  .showNavigation &,
  [data-page='main'] .wideView & {
    min-height: 100px;
    height: $headerHeightMain;
  }

  [data-page='main'] .wideView & {
    justify-content: center;
    top: -2rem;
  }

  .PageHeaderWrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    max-width: 700px;
  }

  [data-page='regular'] :not(.wideView) & {
    .PageHeaderWrapper {
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }
  [data-page='regular'] .wideView & {
    // ATTENTION: Adjust header elements to fit main content column width
    .PageHeaderWrapper {
      padding: 0;
    }

    .PageHeaderBrand {
      margin-left: -1.2vw;
    }

    .PageHeaderNavigation {
      margin-right: -0.25em;
    }
  }

  [data-page='main'] .mobileView & {
    justify-content: flex-start;
    top: 0;
    .PageHeaderWrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .PageHeaderLogo {
        display: none;
      }
    }
  }

  .PageHeaderBrand {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: stretch;
    [data-page='main'] body:not(.mobileView) & {
      display: none;
    }
    [data-page='regular'] & {
      flex: 1;
    }
    .navbar-toggler {
      align-items: center;
      justify-content: center;
      $size: 2.75em;
      height: $size;
      width: $size;
    }
  }

  .PageHeaderContent {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    gap: 1em;
  }
}
