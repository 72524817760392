/**
 * @module PageHeaderToolbar
 * @changed 2024.06.22, 03:54
 */

@use 'sass:color';

@import '../shared';

.PageHeaderToolbar {
  display: flex;
  gap: 0.5em;
  margin: 0;
  padding: 0;
  list-style: none;

  $itemSize: 2em;

  html:not([data-theme]) & .theme-dark,
  [data-theme='light'] & .theme-dark,
  [data-theme='dark'] & .theme-light {
    display: none;
  }

  a {
    width: $itemSize;
    height: $itemSize;
    border-radius: 50%;
    text-decoration: none;
    .onRed & {
      opacity: 0.5;
    }
    &,
    & i {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    background-color: transparent;
    border: 1px solid transparent;
    &,
    & i {
      transition: all 100ms;
    }
    &:hover {
      opacity: 1;
      background-color: color.change(#fff, $alpha: 0.1);
      i {
        transform: scale(1.2);
      }
    }
    &:active {
      background-color: color.change(#fff, $alpha: 0.5);
    }
  }
}
